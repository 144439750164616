import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
  production: false,
  urlServiceJava: 'https://apihomol.ebarn.com.br',
  //urlServerNode: 'http://localhost:3030',
  urlServerNode: 'https://apinodehomol.ebarn.com.br',
  urlEbarnTemplates: 'https://apinodehomol.ebarn.com.br',
  appUrl: 'https://teste.cxm.ebarn.com.br',
  homeApp: 'https://teste.app.ebarn.com.br',
  urlLandingPages: 'https://teste.cxm.ebarn.com.br',
  nome: 'ebarn',
  whatsappSuporte: '5562998534185',
  indexHtml: {
    facebookDomainVerification: 'trjirhr7hmuoogvlxvy96fwhdmqyn9',
    pixelFacebook: '',
    googleAnalytics: '',
    googleTagManager: '',
    pixelLinkedin: '',
    pixelTwitter: ''
  },
  googlePlay: 'https://play.google.com/store/apps/details?id=br.com.ebarn.app',
  appleStore: 'https://apps.apple.com/br/app/ebarn/id1515576910?l=pt-br',
  redesSociais: {
    youtube: 'https://www.youtube.com/channel/UCptk7lNNsSeIwo1Rsh4VUUA',
    facebook: 'https://www.facebook.com/ebarn.agro',
    instagram: 'https://www.instagram.com/ebarn.agro',
    linkedin: 'https://www.linkedin.com/company/ebarn'
  },
  repos3: 'https://repo-files-customers.s3.sa-east-1.amazonaws.com/ebarn-homol',
  repos3Master: 'https://repo-files-customers.s3.sa-east-1.amazonaws.com/ebarn-homol',
  logoPredominaCorPrimaria: true,
  mapBoxUrl: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
  mapBoxToken:
    'pk.eyJ1IjoianJmZXJuYW5kbyIsImEiOiJjbGFxdzE0cG8wa3R0M3Buc3ZzZm93cG04In0.8g1J41Qw3Hl9zVlBpKmnUw',
  programaPontosV2: false
};
